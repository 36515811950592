<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveUser)">
                    <h3><font-awesome-icon icon="user-circle"></font-awesome-icon> Datos generales</h3>
                    <br>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-md-right text-left"><label>Usuario</label></b-col>
                        <b-col cols="12" md="8" lg="6">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-input v-model="user.username" autocomplete="false" placeholder="Usuario"
                                              :state="errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback class="text-left">Ingresa el usuario</b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-md-right text-left"><label>Nombre</label></b-col>
                        <b-col cols="12" md="8" lg="6">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-input v-model="user.name" autocomplete="false" placeholder="Nombre"
                                              :state="errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback class="text-left">Ingresa el nombre</b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-md-right text-left"><label>Correo</label></b-col>
                        <b-col cols="12" md="8" lg="6">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-input v-model="user.email" autocomplete="false" placeholder="Correo"
                                              :state="errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback class="text-left">Ingresa el correo</b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="my-4">
                        <b-col offset-md="4" cols="12" md="8" lg="6">
                            <b-button variant="primary" type="submit" block><font-awesome-icon icon="save"></font-awesome-icon> Actualizar</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <br>
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(changePassword)">
                    <h3 class="mt-4"><font-awesome-icon icon="lock"></font-awesome-icon> Cambiar contraseña</h3>
                    <br>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-md-right text-left"><label>Contraseña actual</label></b-col>
                        <b-col cols="12" md="8" lg="6" class="mb-2">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-input v-model="user.password" autocomplete="false" type="password"
                                              placeholder="Contraseña actual" :state="errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback class="text-left">Ingresa la contraseña actual</b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-md-right text-left"><label>Nueva contraseña</label></b-col>
                        <b-col cols="12" md="8" lg="6" class="mb-2">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-input-group>
                                    <b-form-input v-model="user.newPassword" autocomplete="false" :type=" showPassword ? 'text' : 'password'"
                                                  placeholder="Nueva contraseña" :state="errors[0] ? false : null"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :variant="errors[0] ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover
                                                  title="Mostrar contraseña" @click="showPassword = true" @mouseout="showPassword = false"
                                                  style="border-top-right-radius: .25rem !important; border-bottom-right-radius: .25rem !important;">
                                            <font-awesome-icon icon="eye"></font-awesome-icon>
                                        </b-button>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback class="text-left">Ingresa la nueva contraseña</b-form-invalid-feedback>
                                </b-input-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3 mb-4">
                        <b-col offset-md="4" cols="12" md="8" lg="6">
                            <b-button variant="primary" type="submit" block><font-awesome-icon icon="exchange-alt"></font-awesome-icon> Cambiar contraseña</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" @click="goBack" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { userUrl, userChangePasswordUrl } from '@routes';
import * as constants from '@constants';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

export default {
    data() {
        return {
            user: {
                id: 0,
                name: '',
                username: '',
                email: '',
                password: '',
                newPassword: ''
            },
            breadcrumb: {
                title: 'Mi perfil',
                path: [
                    {
                        name: 'Profile',
                        text: 'Mi perfil'
                    }
                ]
            },
            showPassword: false,
            isLoading: false
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);

        let user = JSON.parse(localStorage.getItem('userInfo'));

        if (user) {
            this.user.id = user.id;
            this.user.name = user.name;
            this.user.email = user.email;
        }

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(userUrl(), {
                params: {
                    id: this.user.id,
                    application: this.application
                }
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let user = response.data.user;

                    this.user.id = user.id;
                    this.user.name = user.name;
                    this.user.username = user.username;
                    this.user.email = user.email;

                    this.oldSignature = user.signature ?? null;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveUser() {
            this.$swal({
                title: '¿Actualizar datos generales?',
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    let data = {
                        user: this.user
                    };

                    let formData = new FormData();
                    formData.append('data', JSON.stringify(data));

                    this.axios.post(userUrl(), formData).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: 'Datos generales',
                                variant: 'success'
                            });

                            let userInfo = JSON.parse(localStorage.getItem('userInfo'));

                            userInfo.id = this.user.id;
                            userInfo.name = this.user.name;
                            userInfo.username = this.user.username;
                            userInfo.email = this.user.email;

                            localStorage.setItem('userInfo', JSON.stringify(userInfo));

                            this.$emit('user-data-change', this.user);
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: 'Datos generales',
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar los datos generales.', {
                            title: 'Datos generales',
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        changePassword() {
            this.$swal({
                title: '¿Cambiar contraseña?',
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;

                    let data = {
                        application: this.application,
                        userId: this.user.id,
                        newPassword: this.user.newPassword,
                        currentPassword: this.user.password
                    };

                    this.axios.put(userChangePasswordUrl(), data).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: 'Cambiar contraseña',
                                variant: 'success'
                            });
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: 'Cambiar contraseña',
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al cambiar la contraseña.', {
                            title: 'Cambiar contraseña',
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        goBack() {
            this.$router.go(-1);
        }
    },
    computed: {

    },
    watch: {

    }
};
</script>

<style scoped>
</style>